import { PageRendererProps } from "gatsby"
import React, { ReactNode } from "react"
import { MDXProvider } from "@mdx-js/react"
import styled from "styled-components"
import { rhythm, styledScale } from "../utils/typography"
import { FadeLink } from "./link"

import CodeBlock from "./codeblock"

interface Props extends PageRendererProps {
  title: string
  children: ReactNode
}

const StyledH1 = styled.h1`
  ${styledScale(1.1)};
  margin-bottom: ${rhythm(1.5)};
  margin-top: 0;
`

const StyledH3 = styled.h3`
  font-family: Montserrat, sans-serif;
  margin-top: 0;
`

const StyledLink = styled(FadeLink)`
  box-shadow: none;
  color: inherit;
  text-decoration: none;
`

const Content = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${rhythm(24)};
  padding: ${`${rhythm(1.5)} ${rhythm(3 / 4)}`};
`

const components = {
  pre: (props: any) => <div {...props} />,
  code: CodeBlock,
}

export const Layout = (props: Props) => {
  const { title, children } = props

  const HeaderTitle = StyledH3

  return (
    <Content>
      <header>
        <HeaderTitle>
          <StyledLink to={`/`}>{title}</StyledLink>
        </HeaderTitle>
        <script defer data-domain="akshay.oorja.io" data-api="https://khabri.oorja.io/api/event" src="/web/scripts/khabri.js"></script>
      </header>
      <main>
        <MDXProvider components={components}>{children}</MDXProvider>
      </main>
      <footer>
        © {new Date().getFullYear()} | Akshay Kumar |{" "}
        <FadeLink to={`/`}>About </FadeLink>
      </footer>
    </Content>
  )
}
